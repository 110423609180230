<template>
  <div class="page-wrap">
    <div class="session-form-hold">
      <base-card :loading="loading" v-if="!ticket">
        <v-card-text class="text-center">
          <img
            class="mb-4"
            height="50"
            src="@/assets/images/logo/logo-dark.svg"
            alt=""
          />
          <template v-if="loading && !error">
            <v-alert
              border="left"
              class="text-left"
              text
              icon="mdi-information-outline"
              type="info"
            >
              Verificando ingresso, por favor aguarde
            </v-alert>
          </template>
          <template v-else>
            <v-alert
              border="left"
              class="text-left"
              text
              icon="mdi-alert-circle-outline"
              type="error"
            >
              {{ error }}
            </v-alert>
            <v-btn
              color="secondary"
              class="mt-4"
              block
              :to="{
                name: 'sign-in',
              }"
            >
              Voltar
            </v-btn>
          </template>
        </v-card-text>
      </base-card>
      <div v-if="ticket">
        <v-card class="rounded-lg">
          <v-img
            class="rounded-top-lg"
            :src="ticket.TicketBlock.TicketGroup.Party.cover"
            :aspect-ratio="16 / 9"
          />
          <v-card-text>
            <div class="d-flex gap-2 mb-1 justify-space-between align-center">
              <h5 class="mb-0">
                {{ ticket.TicketBlock.TicketGroup.Party.name }}
              </h5>
              <v-chip label color="success" x-small>Válido</v-chip>
            </div>
            <div class="d-flex gap-2 justify-space-between align-center">
              <h6 class="mb-0">{{ ticket.TicketBlock.TicketGroup.name }}</h6>
              <h6 class="mb-0">{{ ticket.TicketBlock.name }}</h6>
            </div>
            <v-divider class="my-3" />
            <h6>Proprietário</h6>
            <div class="d-flex gap-2 align-center mb-2">
              <v-avatar color="primary" size="36">
                <v-icon>mdi-account</v-icon>
              </v-avatar>
              <b>{{ ticket.Owner.name }}</b>
            </div>
            <v-alert color="warning" text class="mb-0">
              Esse convite é pessoal e intransferível. Não poderá ser utilizado
              por outra pessoa.
            </v-alert>
          </v-card-text>
        </v-card>
        <v-btn block href="/" text class="mt-2" color="white">
          ir para o app
        </v-btn>
      </div>
    </div>
    <re-captcha
      ref="recaptcha"
      auto-run
      @error="recaptchaError"
      @close="recaptchaError"
      @success="readToken"
    ></re-captcha>
  </div>
</template>
<script>
import ReCaptcha from "@/components/global/ReCaptcha";
import VERIFY from "@/services/verify";
import { mapGetters } from "vuex";

export default {
  name: "VerifyTicket",
  metaInfo: {
    title: "Verificar Ingresso",
  },
  data() {
    return {
      show: false,
      loading: true,
      error: false,
      innerLoading: false,
      innerError: false,
      ticket: null,
    };
  },
  methods: {
    recaptchaError() {
      this.loading = false;
      this.innerLoading = false;
      this.error = "Ocorreu ao validar o recaptcha, atualize a página e tente novamente.";
    },
    
    async readToken(recaptcha) {
      try {
        let id = this.$route.params.id;
        let { ticket } = await VERIFY.ticket(id, recaptcha);
        this.loading = false;
        this.innerLoading = false;
        this.ticket = ticket;
      } catch (e) {
        this.loading = false;
        this.error = e.message;
      }
    },
  },
  computed: {
    ...mapGetters("app", ["tickets"]),
  },
  mounted() {
    let id = this.$route.params.id;
    if (!id) {
      this.loading = false;
      this.error =
        "Você precisa acessar essa página a partir do link enviado para seu WhatsApp. Não recebeu o link? Clique em voltar e tente novamente.";
    }
    if (this.tickets && this.tickets.length) {
      const ticket = this.tickets.find((t) => t.code == id);
      if (ticket)
        return this.$router.replace({
          name: "app.ticket.details",
          params: { id: ticket.id },
        });
    }
  },
  components: {
    ReCaptcha,
  },
};
</script>
<style lang="scss" scoped>
.page-wrap {
  background-color: #B01C3F !important;
  display: flex;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;
}
.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
</style>
